import React from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Magento2__Banner from "../images/magento2-banner.png"
import {Helmet} from "react-helmet/es/Helmet";

export default () => (
    <Layout>
        <Helmet>
            <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
            <meta name="description"
                  content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
            <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
            <meta name="description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:site_name" content="Cueblocks"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
            <meta property="og:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta property="og:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta name="twitter:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta name="twitter:site" content="@cueblocks"/>
            <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <div className="cb_expertiseInner">
            <div className="services-inner services-custom">

                <img src={Magento2__Banner} alt="CueBlocks" className="img-adjust" />

                <p></p>
                <h4>CueBlocks is</h4>
                <h3>
                    now a Magento 2<sup className="reg">®</sup> Trained Solution Partner!
                </h3>
                <p>CueBlocks is now , officially, a certified Magento 2<sup className="reg">®</sup> Trained agency to provide Magento
                2<sup className="reg">®</sup> based development solutions for eCommerce stores.</p>
                <p>As the eCommerce world awaited the next generation, innovative eCommerce platform, our certified Magento developers
                  ensured that CueBlocks is among the 87 chosen Magento Trained Solution Partners. We fulfilled all the Magento
                    2.0<sup className="reg">®</sup> program requirements and completed exercises that validated our ability to execute
                     Magento 2.0<sup className="reg">®</sup> principles to become part of this much awaited Magento 2.0<sup
                        className="reg">®</sup> super-program!</p>
                <p>We have been contributing to the platform's functionalities and features in the form of extensions and providing
                  product feedback from time to time. Now, with this specialization, we are ready for an even more exciting Magento
                    adventure!</p>
                <h4> What All Can We Do Now?</h4>
                <p>Magento 2.0<sup className="reg">®</sup> platform was launched to empower both the millennial store owner as well as the
                  millennial shopper. The new digital eCommerce platform assures enhanced scalability, efficient and extensive APIs, a
                  tech stack that can be a developer's dream playground, improved shopper experience and better customer engagement
                 for increased sales and higher conversion rates.</p>
                <p>In short, it's a powerhouse next-gen eCommerce program that promises to deliver what the user and the admin aspire. </p>
                <p>With the “Trained Solution Partner” status, CueBlocks is now able to:</p>
                <ul>
                    <li>Migrate existing Magento based stores to Magento 2.0<sup className="reg">®</sup></li>
                    <li>Provide custom Magento 2<sup className="reg">®</sup> solutions to eCommerce stores: custom builds and upgrades.</li>
                    <li>Help online merchants with Magento 2.0<sup className="reg">®</sup> integration solutions.</li>
                    <li>Prepare your store for an Omnichannel shopping experience</li>
                </ul>
                <h4>  How Can Our Clients Benefit? </h4>
                <p>Without thinking twice, you can now get in touch with us for any or almost every Magento 2.0<sup
                    className="reg">®</sup> related services. With the badge comes both credibility and responsibility and we are excited
                    to deliver some awesome Magento 2<sup className="reg">®</sup> projects.</p>
                <p>So let's get to it straight on!</p>
                <p>If you are planning a move to Magento 2<sup className="reg">®</sup> or upgrade your store, <a href="contact.php" className="link">get in
                touch</a> with us about our Magento 2<sup className="reg">®</sup> services.</p>


                <div className="get-touch">
                    <p>like what you see?</p>
                    <a href="contact.html" className="main_cta">Get in touch</a>
                </div>

                <div className="get-touch learn-casestudy">
                    <h3>Learn how we helped one of our clients speed up their Magento 2 store</h3>
                    <a href="magento-2/case-study/site-speed-ww.html" className="main_cta">Case Study</a>
                </div>
            </div>
        </div>

            <Clutch />

    </Layout>

        )